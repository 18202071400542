html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,
kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,
thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section {
  display: block;
}

ol,ul {
  list-style: none;
}

blockquote,q {
  quotes: none;
}

blockquote:before,blockquote:after,q:before,q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none; /* 去除默认的下划线 */
  outline: none;	/* 去除旧版浏览器的点击后的外虚线框 */
  color: #666;	/* 去除默认的颜色和点击后变化的颜色 */
}

img {
  width: 100%;
  height: 100%;
  display: block;
  /* image-rendering: -moz-crisp-edges;  
  image-rendering: -o-crisp-edges;    
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges; 
  -ms-interpolation-mode: nearest-neighbor; */
}

/* 自定义 */

body {
  font-family: PingFangSC-Regular, Roboto, Helvetica Neue, Helvetica, Tahoma, Arial, PingFang SC-Light, Microsoft YaHei;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  min-width: 1000px;
  max-width: 1920px;
  margin: 0 auto;
  line-height: 1;
  font-size: 14px;
  background-color: #fff;
}

/* 无妨 */
.wf-flex {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

/* .wf-flex-col {
  display: flex;
  flex-direction: column;
} */

.wf-flex-3 {
  flex: 3;
}

.wf-flex-4 {
  flex: 4.5;
}

/* .wf-lh20 {
  line-height: 20px;
} */

.flex-align-center {
  align-items: center;
}

.wf-tal {
  text-align: left;
}

.wf-tar {
  text-align: right;
}

.wf-tac {
  text-align: center;
}

.wf-pt10 {
  padding-top: 10px;
}

.wf-pb30 {
  padding-bottom: 30px;
}

.wf-pt56 {
  padding-top: 56px;
}

.wf-colf {
  color: #fff;
}

.wf-col6 {
  color: #666;
}

.wf-col3 {
  color: #333;
}

.wf-header-ab {
  position: absolute;
  top: 0;
  width: 100%;
  margin: 0 auto;
  min-width: 985px;
  max-width: 1920px;
  overflow: hidden;
}

.wf-header {
  width: 985px;
  margin: 0 auto;
}

.wf-header-title a {
  padding: 5px 0px;
  color: #fff;
}

.wf-header .img-logo {
  width: 140px;
  height: 80px;
  display: block;
}

.wf-header .logo-title {
  margin-top: 10px;
  color: #fff;
  letter-spacing: 2px;
}

.wf-header-title-active a {
  border-bottom: 1px solid;
}

.wf-header .wf-active a:hover {
  border-bottom: 1px solid;
}

.wf-bg {
  height: 779px;
  background-image: url('https://cdn.hfbeta.com/20220228/107874529596441388.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* CSSTransition 动画 */

.page-enter {
  opacity: 0;
}

.page-enter-active {
  opacity: 1;
  -webkit-animation: moveToLeft .6s ease both;
  -moz-animation: moveToLeft .6s ease both;
  animation: moveToLeft .6s ease both;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  -webkit-animation: moveFromRight .6s ease both;
  -moz-animation: moveFromRight .6s ease both;
  animation: moveFromRight .6s ease both;
}

@keyframes moveToLeft {
  to {
    transform: translateX(-100%);
  }
}

@keyframes moveFromRight {
  from {
    transform: translateX(100%);
  }
}
